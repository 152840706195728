/* global PRODUCTION VUE */
// import Vue from 'vue';
import { metrics } from './theme/scripts/ferg-metrics.js';

console.log(`${PRODUCTION ? 'Production Environment' : 'Dev Environment'}`);

if (PRODUCTION) {
	// Production-specific settings go here.
	// console.log = () => {}; 
} else {
	metrics();
}

document.addEventListener('DOMContentLoaded', function() {
	if (!PRODUCTION) console.log('DOMContentLoaded');
	
	// initVue();
	handleHamburgers();
	handleDropdowns();
	handleVideoPlayers();
	handleTestimonials();
});

function handleVideoPlayers() {

    var video = document.getElementById('video');

	if(!video) return;

    var player = new Vimeo.Player(video); 
	var playbutton = document.getElementById('play-button');

	playbutton.addEventListener('click', function(){
		player.play();
	});

    player.on('play', function() {
      playbutton.classList.add('is-hidden');
    });

}

function handleHamburgers() {
	// Get all "navbar-burger" elements
	const navbarBurgers = document.querySelectorAll('.navbar-burger'); // Check if there are any navbar burgers
	
	if (navbarBurgers.length > 0) {
		// Add a click event on each of them
		navbarBurgers.forEach(function(el) {
			el.addEventListener('click', function() {
				// Get the target from the "data-target" attribute
				const target = el.dataset.target;
				const $target = document.getElementById(target); // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
				
				el.classList.toggle('is-active');
				$target.classList.toggle('is-active');
			});
		});
	}
}

function handleDropdowns() {
	const dropdowns = document.querySelectorAll('.navbar-dropdown'); // Check if there are any navbar burgers
	
	if (dropdowns.length > 0) {
		dropdowns.forEach(function(el) {
			const navbarItem = el.parentElement;
			const toggle = navbarItem.querySelector('.navbar-link');
			
			toggle.addEventListener('click', function() {
				navbarItem.classList.toggle('is-active');
				
				document.addEventListener('click', function _listener(e) {
					if (!navbarItem.contains(e.target)) {
						navbarItem.classList.toggle('is-active');
						document.removeEventListener('click', _listener);
					}
				});
			});
		});
	}
}

function handleTestimonials() {
	jQuery('.testimonials .slider').slick(
		{
			dots: true,
			arrows: false,
			autoplay: true,
			speed: 300,
			slidesToShow: 1,
			pauseOnHover: true,
			adaptiveHeight: true,
			autoplaySpeed: 15000
		}
	);
}